@use '~@esi/leaf-design-tokens/lib/web/layouts/breakpoints.scss';

.container {
  margin: 0 auto;
  width: 100%;
  min-height: 450px;
  max-width: 1440px;

  .contentSection {
    max-width: 500px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-self: center;
  }

  @media (min-width: 1140px) {
    height: 750px;
    background-image: url('../../../../../../../apps/enac-provider-web/src/assets/images/landing-bg.webp');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
  }

  .subtitleArea {
    margin-top: 1rem;
  }

  .buttonArea {
    margin-top: 1rem;
  }

  .bodytextArea {
    p {
      font-size: var(--leaf-typography-label-large-font-size);
      line-height: var(--leaf-typography-label-large-line-height);
    }
  }
}
