@use '~@esi/leaf-design-tokens/lib/web/mixins/typography-usage.scss' as
  typography;

.tableWrapper {
  border-radius: var(--leaf-border-radius-default);
  border: 1px solid var(--leaf-color-border-strong);
}

.tableTopper {
  background-color: var(--leaf-color-bg-default);
  border-top-left-radius: var(--leaf-border-radius-default);
  border-top-right-radius: var(--leaf-border-radius-default);
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.table {
  background: var(--leaf-color-bg-default);
  border-radius: var(--leaf-border-radius-default);
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  max-width: 100%;
  display: table;

  thead th {
    @include typography.leaf-typography-label-default;
    border-bottom: 1px solid var(--leaf-color-border-strong);
    text-align: left;
    padding: var(--leaf-spacing-16);

    .thWrap {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .thSortWrap {
      display: flex;
      flex-direction: column;
    }

    .sortButton {
      appearance: none;
      background: transparent;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      gap: 10px;
      text-align: left;
      cursor: pointer;
      font-size: inherit;
      font-family: inherit;
      font-weight: inherit;
      line-height: inherit;
      padding: 0;
    }
  }
  th.thSmall {
    font-size: 0.8rem;
  }
  tbody {
    td {
      @include typography.leaf-typography-body-small;
      border-bottom-width: var(--leaf-border-width-default);
      border-bottom-style: var(--leaf-border-style-default);
      border-bottom-color: var(--leaf-color-border-strong);
      padding: var(--leaf-spacing-16);
      text-align: left;
    }
    tr:last-child td {
      border-bottom: 0;
    }
    td.expandedRow {
      background: var(--leaf-color-bg-subtle);
    }
    tr:last-child td.expandedRow {
      border-bottom-left-radius: var(--leaf-border-radius-default);
      border-bottom-right-radius: var(--leaf-border-radius-default);
    }
  }
  tfoot {
    th {
      @include typography.leaf-typography-body-small;
      padding: var(--leaf-spacing-16);
      background-color: var(--leaf-color-bg-subtle);
      text-align: left;
      border-top-width: var(--leaf-border-width-default);
      border-top-style: var(--leaf-border-style-default);
      border-top-color: var(--leaf-color-border-strong);
      border-bottom: 0;
    }

    th:first-child {
      border-bottom-left-radius: var(--leaf-border-radius-default);
    }
    th:last-child {
      border-bottom-right-radius: var(--leaf-border-radius-default);
    }

    border-top-left-radius: var(--leaf-border-radius-default);
    border-top-right-radius: var(--leaf-border-radius-default);
  }
}

.tableWithChildren {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.paginationContainer {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  padding: 8px 0 8px 16px;
  align-items: center;

  .pageCounts {
    @include typography.leaf-typography-label-small;
  }

  .pageButtons {
    display: flex;
    gap: 16px;
  }
}
