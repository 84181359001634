.panelBody {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}

.panelFooter {
  display: flex;
  gap: 1rem;
  border-top: 1px solid var(--leaf-color-border-strong);
  padding: 1rem 0 0 0;
  margin-top: 1rem;

  leaf-button {
    flex-grow: 1;
  }
}
