.container {
  background-color: var(--leaf-color-bg-brand-strong);
  height: 144px;
  width: 144px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  leaf-icon {
    color: #dafa87; // TODO: use leaf color variable here
  }
}
