.containerDefault,
.containerCustom {
  display: flex;
  background: var(--leaf-color-bg-subtle);
}

.containerDefault {
  padding-bottom: 6rem;
  flex-direction: column;
}

.containerPadding {
  padding-top: 2.5rem;
  padding-bottom: 6rem;
}

@media (min-width: 1140px) {
  .containerPadding {
    padding-top: 4.5rem;
  }
}

.containerDefaultTop {
  background-color: var(--leaf-color-bg-default);
  padding: 2.5rem 1.5rem 3rem 1.5rem;
  display: flex;
  // align-items: center;
  min-height: 148px;
}

.containerHeaderWrap {
  display: flex;
  align-items: center;
  leaf-button {
    --leaf-color-button-tertiary-content: var(--leaf-color-content-default);
    margin-right: 1rem;
  }

  .printBtn {
    margin-left: auto;
    --icon-height: 24px;
    --icon-width: 24px;
    --leaf-color-button-tertiary-content: var(--leaf-color-content-brand);
  }
}

.containerDefaultBottom {
  padding-top: 3rem;
  background-color: var(--leaf-color-bg-subtle);
}

@media (min-width: 1140px) {
  .containerBgTopRight {
    background-image: url('./bg-lines-top-right-standard.svg');
    background-repeat: no-repeat;
    background-position: top right;
  }

  .containerBgTopRightAlt {
    background-image: url('./bg-lines-top-right-alt.svg');
    background-repeat: no-repeat;
    background-position: top right;
  }

  .containerBgBottomRight {
    background-image: url('./bg-lines-bottom-right-standard.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
  }
}

@media print {
  .printBtn {
    display: none;
  }
  .containerDefaultBottom {
    padding-top: 0;
  }
  .containerDefaultTop {
    padding: 0;
    min-height: auto;
  }
}
