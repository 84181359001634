.container {
  position: relative;
}

.panel {
  position: absolute;
  top: 38px;

  padding: 0.75rem;
  border-width: var(--leaf-border-width-default);
  border-style: solid;
  border-color: var(--leaf-color-border-strong);
  border-radius: var(--leaf-border-radius-default);

  z-index: 10;

  background-color: var(--leaf-color-bg-default);
  box-shadow: 0 8px 6px 0 rgb(0 0 0 / 0.1);
}

/* todo: revisit after latest design token udpate to 1.5 */
.filterBtn {
  --leaf-color-button-secondary-border: var(--leaf-color-button-primary-border);
  --leaf-color-button-secondary-bg: var(--leaf-color-bg-default);
  --leaf-color-button-secondary-content: var(
    --leaf-color-button-primary-border
  );
  --leaf-icon-fill: var(--leaf-color-button-primary-border);
}
