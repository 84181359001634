.message {
  position: fixed;
  height: 62px;
  width: 450px;
  bottom: 20px;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, 0);

  .messageText {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    font-size: 1rem;
  }
}
