.fileItem {
  .fileInfo {
    display: flex;
    align-items: center;

    .deleteIcon {
      padding-right: 10px;
    }

    .uploadStatusContainer {
      padding-left: 16px;
      .success,
      .error {
        margin-top: -4px;
      }

      .success {
        color: var(--leaf-color-content-brand-strong);
      }
    }
  }
  .error {
    color: var(--leaf-color-content-error);
  }
}
